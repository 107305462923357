$primary: #3154a1;
$secondary: #e73e22;
$light: #e3e3e3;
$dark: #1d1d1b;

$bezier: cubic-bezier(0.165, 0.84, 0.44, 1);

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@import "_vars";
@import "bootstrap/bootstrap.scss";

body {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-wrapper {
  display: flex;
  transition: transform 800ms $bezier;

  // &.shifted {
  //   transform: translateX(calc(-50vw + 45px));
  // }
}

.page {
  width: calc(100vw - 90px);
  height: 100vh;
  display: flex;

  &.active {
    h1 {
      opacity: 0;
      visibility: hidden;
      animation: fadeIn 300ms ease-in-out forwards;
    }
  }

  .page-content-inner {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.transition-out-1 {
  transform: translate3d(calc(100vw - 90px), 50vh, 0) !important;
}

// .transition-out-1-shifted {
//   transform: translate3d(calc(50vw - 45px), 50vh, 0) !important;
// }

.transition-out-2 {
  transform: translate3d(calc(100vw - 90px), -50vh, 0) !important;
}

// .transition-out-2-shifted {
//   transform: translate3d(calc(50vw - 45px), -50vh, 0) !important;
// }

.transition-out-3 {
  transform: translate3d(calc(-100vw + 90px), 50vh, 0) !important;
}

// .transition-out-3-shifted {
//   transform: translate3d(calc(-150vw + 135px), 50vh, 0) !important;
// }

.transition-out-4 {
  transform: translate3d(calc(-100vw + 90px), -50vh, 0) !important;
}

// .transition-out-4-shifted {
//   transform: translate3d(calc(-150vw + 135px), -50vh, 0) !important;
// }

@media (max-width: 767.98px) {
  .page {
    width: calc(100vw - 10px);
  }

  .transition-out-1 {
    transform: translate3d(calc(100vw - 10px), 50vh, 0) !important;
  }

  .transition-out-2 {
    transform: translate3d(calc(100vw - 10px), -50vh, 0) !important;
  }

  .transition-out-3 {
    transform: translate3d(calc(-100vw + 10px), 50vh, 0) !important;
  }

  .transition-out-4 {
    transform: translate3d(calc(-100vw + 10px), -50vh, 0) !important;
  }
}
